import api_server from "../axios/baseURL";
import axios from "axios";

const Login = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('code') !== null) {
    if (localStorage.getItem("sso_step") == 'code') {
      localStorage.setItem("sso_step", 'token');
      var pkce = localStorage.getItem("pkce_code_verifier");
      var cha = await pkceChallengeFromVerifier(pkce);
      getSsoToken();
    }
  } else {
      localStorage.setItem("sso_step", 'code');
      getSsoCode();
  }
  return (<></>);
}

function base64urlencode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  var data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

const pkceChallengeFromVerifier = async (v) => {
  var hashed = await sha256(v);
  return base64urlencode(hashed);
}

const getSsoCode = async () => {
  try {
    var state = generateRandomString();
    localStorage.setItem("pkce_state", state);
    var code_verifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", code_verifier);
    window.location = "https://my.ofgconnect.co.uk/connect/authorize"
      + "?response_type=code"
      + "&client_id="+encodeURIComponent("503b99f251e14ed696978e56cea8925d")
      + "&state="+encodeURIComponent(state)
      + "&scope="+encodeURIComponent("staff_reference openid profile")
      + "&redirect_uri="+encodeURIComponent(window.location.origin.toString()+"/login")
      + "&code_challenge="+encodeURIComponent(await pkceChallengeFromVerifier(code_verifier))
      + "&code_challenge_method=S256";
  } catch(err) {
    console.error(err);
    alert('Your account does not have permission to access Engagement Hub. Please contact your manager or IT support.')
    window.location = "https://portal.ofgconnect.co.uk/pages/home/";
    return false;
  }
}

const getSsoToken = async () => {
  var urlParams = new URLSearchParams(window.location.search);
  var params = {
    grant_type: "authorization_code",
    code: urlParams.get('code'),
    client_id: "503b99f251e14ed696978e56cea8925d",
    redirect_uri: window.location.origin.toString()+"/login",
    code_verifier: localStorage.getItem("pkce_code_verifier")
  }
  var request = new XMLHttpRequest();
  request.open('POST', "https://my.ofgconnect.co.uk/connect/token", true);
  request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  request.onload = await function() {
    var body = {};
    try {
      body = JSON.parse(request.response);
      axios.post(api_server+'/auth/login', JSON.parse(request.response))
      .then(async (res) => {
        if (res.request.status === 200) {
          localStorage.setItem("token",JSON.stringify(res.data.api));
          localStorage.setItem("user",JSON.stringify(res.data.user));
          localStorage.setItem("usepermission",JSON.stringify(res.data.permissions));
          window.location.href = "/";
        } else {
          alert('Your account does not have permission to access Engagement Hub. Please contact your manager or IT support.')
          window.location.href = "https://portal.ofgconnect.co.uk/pages/home/";
        }
      })
      .catch((res,err) => {
        console.error(err);
        alert('Your account does not have permission to access Engagement Hub. Please contact your manager or IT support.')
        window.location.href = "https://portal.ofgconnect.co.uk/pages/home/";
      });
    } catch(err) {
      console.error(err);
      alert('Your account does not have permission to access Engagement Hub. Please contact your manager or IT support.')
      window.location.href = "https://portal.ofgconnect.co.uk/pages/home/";
    }
  }
  request.onerror = function() {
    console.error(request);
    return false;
  }
  var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  request.send(body);
}

export default Login;