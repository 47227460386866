import api_server from "../axios/baseURL";
import axios from "axios";

const Logout = async () => {
  const auth = JSON.parse(localStorage.getItem("token")); 
  await axios.post(api_server+'/auth/logout', {}, {
    headers: {
        "Authorization": 'bearer '+auth?.token
    }
  }).then((res) => {
    localStorage.clear();
    window.location.href = "https://portal.ofgconnect.co.uk/pages/home/";
  }).catch((err) => {
    console.error(err);
    localStorage.clear();
    window.location.href = "https://portal.ofgconnect.co.uk/pages/home/";
  })
  return (<></>);
}

export default Logout;